import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"24px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, {
        style: _normalizeStyle({
        margin: '24px 16px',
        padding: '0 24px',
        background: '#fff',
        height: 'calc(100vh - 64px - 48px)',
        overflow: 'auto',
      })
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      }, 8, ["style"])
    ]),
    _: 1
  }))
}